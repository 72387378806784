'use strict'

###*
 # @ngdoc service
 # @name users.factory:UserGroupManager

 # @description

###
angular
  .module 'users'
  .factory 'UserGroupManager', [
    'Restangular'
    'TenantManager'
    'UserRoleManager'
    'MundoDefaultManager'
    'UserContextManager'
    'UserPermissions'
    '$sce'
    '$mdDialog'
    '$rootScope'
    (
      Restangular
      TenantManager
      UserRoleManager
      MundoDefaultManager
      UserContextManager
      UserPermissions
      $sce
      $mdDialog
      $rootScope
    )->
      UserGroupManagerBase = new MundoDefaultManager()

      UserGroupManagerBase.setUrl('user_contexts/groups')
      UserGroupManagerBase.setAutoTenant()
      UserGroupManagerBase.setNewObject(['label', 'roles'])
      UserGroupManagerBase.setUpdateObject(['label', 'roles'])
      UserGroupManagerBase.setSearchFields(['label', 'tenant.label', 'roles.label'])


      UserGroupManagerBase.new = (data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UserGroupManagerBase.update = (id, data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)
        console.log data
        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UserGroupManagerBase.getNewForm = ->
        [
          key: 'label'
          type: 'input'
          name: 'label'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            multiple: true,
            placeholder: 'roles'
            required: false
            labelProp: "label"
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ]

      UserGroupManagerBase.getEditForm = (data)->
        [
          key: 'label'
          type: 'input'
          defaultValue: data.label,
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue : data.roles
          templateOptions:
            label: 'Roles'
            multiple: true,
            placeholder: 'roles'
            required: false
            labelProp: "label"
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ]

      UserGroupManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
        ,
          key: 'userContexts.user.username'
          title: 'datatable.label.members'
          multiple: 'userContexts'
          hideInList: true

        ]

      #permission needed to edit/delete an entity
      UserGroupManagerBase.editPermission = 'manage all MundoMosaUserBundle:UserContextGroup entities'

      UserGroupManagerBase.valuePostProcessor = (path, value, row) ->

        if path == 'userContexts.user.username'

          vals = ''
          usernames = []
          for context in row.userContexts
            if context.user? and
              !context.deletedAt and
              context.tenant.id == $rootScope.user.activeUserContext.tenant.id and
              !context.isGroup
                usernames.push context.user.username

          usernames.sort((a,b) -> if b.toLowerCase() < a.toLowerCase() then 1 else -1)

          for test in usernames
            vals += test + ', '

          return $sce.trustAsHtml(vals.substring(0,vals.length-2))

        return value

      #add extra action to add members to group
      ## This code looks just awfull, Im sorry
      addAddMemberAction = () ->
        addMember = {}
        addMember.icon = 'add'
        addMember.text = 'datatable.label.addmember'

        formcontroller = (
            title
            entityManager
            entity
            $mdDialog
            $scope
            _
          ) ->
            @model = {}
            @errors = []
            errors: @errors
            model: @model
            title: title
            cancel: () -> $mdDialog.cancel()
            submit: () =>
              addMemberSubmit($mdDialog, @model, entity, @errors)
            fields:
              [
                key: 'username'
                type: 'input'
                ngModelAttrs:
                  focusOn:
                    attribute: 'focus-on'
                templateOptions:
                  label: 'Username or email'
                  placeholder: 'username/email'
                  required: true
                  focusOn: true
              ]

        addMember.action = (id, row) ->
          if UserPermissions.check UserGroupManagerBase.editPermission
            $mdDialog.show
              templateUrl: 'mundo-components/mundo-form-dialog/views/mundo-form-dialog.modal.tpl.html'
              controller: formcontroller
              controllerAs: 'formDialogCtrl'
              locals:
                title: "Voeg een lid toe aan #{row.label}"
                entity: row
                entityManager: UserGroupManagerBase
          else
            alert("You do not have the right permissions for this action")

        UserGroupManagerBase.addExtraAction(addMember)

      addMemberSubmit = ($mdDialog, model, entity, errors) ->
        UserContextManager.getFullList()
          .then(
            (userlist) ->
              name = model.username
              user = _.filter userlist, (u) ->
                u.type == 'UserContext' and (u.user.username == model.username or u.user.email == name)
              console.log user
              user = if user.length? and user.length > 0 then user[0] else undefined
              if user?
                exists = false
                userContexts = entity.userContexts
                for context in entity.userContexts
                  if context.id == user.id
                    exists = true

                if exists
                  errors.push('key': 'This user is already a member', 'value': [name])
                else
                  groups = []
                  for ct in user.userContextGroups
                    groups.push ct.id
                  groups.push entity.id
                  UserGroupManagerBase.transport
                    .all('user_contexts')
                    .one(user.id)
                    .customPUT({userContextGroups: groups, user: user.user.id})
                    .then(
                      () -> $mdDialog.hide('true')
                      (error) ->
                        for key, value of error.data.errors.children
                          if value.errors?
                            errors.push('key': key, 'value': value.errors)
                    )
              else
                errors.push('key': 'Could not find', 'value': [name])
            (error) ->
              console.log error
          )

      addAddMemberAction()


      UserGroupManagerBase
  ]
